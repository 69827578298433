export class ConfigSettings {

    constructor(value, user?: string, pdw?: string) {
        this.levelOneDealers = value.levelOneDealers;
        this.showsServiceItemPrices = value.showsServiceItemPrices;
        this.serverUrl = value.ServerUrl;
        this.version = value.Version;
        this.communityId = value.CommunityId;
        this.partnerId = value.PartnerId;
       this.hasSolServer = value.hasSolServer
        this.isCRM = value.isCRM
        // this.mapUrl = value.mapUrl;
        this.activationUser = value.ActivateionUser ? value.ActivateionUser : user;
        this.activationPassword = value.ActivateionPassword ? value.ActivateionPassword : pdw;
        this.dealerOrderAttr = value.dealerOrderAttr;
        this.nonAutolineDealers = value.nonAutolineDealers;
        this.maintenanceMode = value.maintenanceMode;
        this.bmwUrl = value.BMUrl;
        this.miniUrl = value.MIUrl;
        this.bmwTel = value.BMTel;
        this.miniTel = value.MITel;
        this.country = value.Country;
        this.bmwEmail = value.BMEmail;
        this.miniEmail = value.MIEmail;
        this.login = value.Login;
        this.dealerLock = value.DealerLock;
        this.customerActivation = value.CustomerActivation;
        this.customActivationFunnel = value.CustomActivationFunnel;
        this.vehicleSearchVin = JSON.parse(value.VehicleSearchVIN);
        this.vehicleSearchReg = JSON.parse(value.VehicleSearchReg);
        this.vinRequired = value.VINRequired;
        this.regRequired = value.RegRequired;
        this.serviceHistory = value.ServiceHistory;
        this.amendBookings = value.AmendBookings;
        this.deleteBookings = value.DeleteBookings;
        this.privacyConsent = value.PrivacyConsent;
        this.registerExtended = value.RegisterExtended;
        this.registerExtendedRequired = value.RegisterExtendedRequired;
        this.validationRule = value.ValidationRule;
        this.validationFormat = value.ValidationFormat;
        this.blockBooking = value.BlockBooking;
        this.blockTotalLoss = value.blockTotalLoss;
        this.altConfirmationText = value.altConfirmationText;
        this.altRegistrationsValidationText = value.altRegistrationsValidationText;
        this.calendarDays = value.calendarDays;
        this.displayMobilityPrice = value.displayMobilityPrice;
        this.displayMobilityTip = value.displayMobilityTip;
        this.deleteProfile = value.deleteProfile;
        this.displayCosyImages = value.displayCosyImages;
        this.currency = value.currency;
        this.iframe = value.iframe;
        this.noManualAdd = value.NoManualAdd;
        this.variant = value.Variant;
        this.vehicleName = value.VehicleName;
        this.useModelYearOnAddVehicle = value.UseModelYearOnAddVehicle;
        this.marketModelName = value.MarketModelName;
        this.addVehicleName = value.AddVehicleName;
        this.anyAdvisor = value.anyAdvisor;
        this.advisorImgs = value.advisorImgs;
        this.seasonalImgs = value.seasonalImgs;
        this.zoom = value.zoom;
        this.multilingual = value.multilingual;
        this.regio = value.regio;
        this.region = value.region;
        this.language = value.language;
        this.useStaticModelList = value.useStaticModelList;
        this.includeUserManual = value.includeUserManual;
        this.titanCommunity = value.titanCommunity;
        this.fullRecallEnabled = value.fullRecallEnabled;
        this.dealerInclusion = [];
        this.canFilterDealers = value.canFilterDealers;
        if (value.dealerInclusion)
            value.dealerInclusion.forEach(dealerCode => {
                this.dealerInclusion.push({
                    dealerCode,
                    brands: value[dealerCode].brands,
                    url: value[dealerCode].url,
                    levelOne: value[dealerCode].levelOne ? value[dealerCode].levelOne : undefined
                });
            });

        if (value.FormValidation) {
            this.formValidation = {
                mobileTelNo: value.FormValidation.MobileTelNo,
                mobileTelNoMsg: value.FormValidation.MobileTelNoMsg,
                maxMobileLength: value.FormValidation.maxMobileLength,
                minMobileLength: value.FormValidation.minMobileLength,
                password: value.FormValidation.Password,
                postalCode: value.FormValidation.PostalCode,
                regNumber: value.FormValidation.RegNumber
            };
        }

        if (value.nonAutoline) {
            this.nonAutoline = value.nonAutoline;
        }

        this.ignoreWorkshopCapacity = value.ignoreWorkshopCapacity;
        this.homeUrl = value.homeUrl;
        this.allModelsUrl = value.allModelsUrl;
        this.servicesUrl = value.servicesUrl;
        this.worldUrl = value.worldUrl;
        this.menuFiveUrl = value.menuFiveUrl;
        this.buildUrl = value.buildUrl;
        this.lifestyleUrl = value.lifestyleUrl;
        this.brochureUrl = value.brochureUrl;
        this.careersUrl = value.careersUrl;
        this.contactUrl = value.contactUrl;
        this.newsletterUrl = value.newsletterUrl;
        this.corporateSalesUrl = value.corporateSalesUrl;
        this.motorradUrl = value.motorradUrl;
        this.cookieUrl = value.cookieUrl;
        this.disclaimerUrl = value.disclaimerUrl;
        this.facebookUrl = value.facebookUrl;
        this.twitterUrl = value.twitterUrl;
        this.lineUrl = value.lineUrl;
        this.instagramUrl = value.instagramUrl;
        this.youtubeUrl = value.youtubeUrl;
        this.contractCode = value.contractCode;
        this.businessUnit = value.businessUnit;
        this.vinImg = value.vinImg;
        this.oemId = value.oemId;
        this.useAlternateModelCode = value.useAlternateModelCode;
        this.includeFaq = value.includeFAQ;
        this.displayRegNumberMessage = value.displayRegNumberMessage;
        this.getMakeModelVariantAll = value.getMakeModelVariantAll;
        this.filterModelListBMW = value.filterModelListBMW;
        this.switchAdvisorWorkflow = value.switchAdvisorWorkflow;
        this.vehicleDropOffMassage = value.vehicleDropOffMassage;
        this.technicalCampaignMessageLink = value.technicalCampaignMessageLink;
        this.privacyPolicy = value.privacyPolicy;
        this.hideMoreTitles = value.hideMoreTitles;
        this.altImage = value.altImage;
        this.showSearchRegMessage = value.showSearchRegMessage;
        this.hasTitanDealers = value.hasTitanDealers;
        this.latitude = value.Latitude;
        this.longitude = value.Longitude;
        this.hasServiceContracts = value.hasServiceContracts
        this.titanMakeCode = value.titanMakeCode;
        this.titanAdminUsername = value.titanAdminUsername;
        this.titanAdminPassword = value.titanAdminPassword;
        this.switchDealer = value.switchDealer;
        this.preventMultipleBookings = value.preventMultipleBookings;
        this.privacyPolicyLayout = value.privacyPolicyLayout;
        this.useVinForVariantCode = value.useVinForVariantCode;
        this.isLevelOne = value.isLevelOne
        if (value.languages) {
            value.languages.forEach(language => {
                this.languages.push({
                    abbreviation: language.abbreviation,
                    language: language.language,
                    direction: language.direction,
                    description: language.description,
                    position: language.position,
                    abbreviationAlt: '',
                    descriptionAlt: '',
                    switch: language.switch
                });
            });
        }
    }
    showsServiceItemPrices: boolean;
    fullRecallEnabled: boolean;
    switchDealer: boolean;
    titanAdminPassword: string;
    titanAdminUsername: string;
    titanCommunity: string;
    titanMakeCode: string;
    latitude: number;
    longitude: number;
    canFilterDealers: boolean;
    hasTitanDealers: boolean;
    showSearchRegMessage: boolean;
    altImage: boolean;
    hideMoreTitles: boolean;
    privacyPolicy: boolean;
    privacyPolicyLayout: string;
    technicalCampaignMessageLink: string;
    includeUserManual: boolean;
    ignoreWorkshopCapacity: boolean;
    useStaticModelList: boolean;
    switchAdvisorWorkflow: boolean;
    vehicleDropOffMassage: boolean;
    filterModelListBMW: boolean;
    includeFaq: boolean;
    useAlternateModelCode: boolean;
    useVinForVariantCode: boolean;
    oemId: string;
    vinImg: boolean;
    languages: Language[] = [];
    contractCode: string;
    businessUnit: string;
    formValidation: FormValidation;
    serverUrl: string;
    version: string;
    communityId: string;
    partnerId: string;
    sharedKey: string;
    dealerInclusion: Dealer[];
    getMakeModelVariantAll: boolean;
    mapUrl: string;
    hasSolServer?: boolean;
    isCRM?: boolean;
    activationUser: string;
    activationPassword: string;
    dealerOrderAttr: string;
    nonAutolineDealers: string;
    isLevelOne?: boolean
    maintenanceMode: boolean;
    bmwUrl: string;
    miniUrl: string;
    bmwTel: string;
    miniTel: string;
    country: string;
    bmwEmail: string;
    miniEmail: string;
    hasServiceContracts: boolean
    login: string;
    dealerLock: boolean;
    customerActivation: boolean;
    customActivationFunnel: boolean;
    vehicleSearchVin: boolean;
    vehicleSearchReg: boolean;
    vinRequired: boolean;
    displayRegNumberMessage: boolean;
    regRequired: boolean;
    serviceHistory: boolean;
    amendBookings: boolean;
    deleteBookings: boolean;
    privacyConsent: boolean;
    registerExtended: boolean;
    registerExtendedRequired: boolean;
    validationRule: string;
    validationFormat: string;
    blockBooking: number;
    blockTotalLoss: boolean;
    altConfirmationText: boolean;
    altRegistrationsValidationText: boolean;
    calendarDays: number;
    displayMobilityPrice: boolean;
    displayMobilityTip: boolean;
    deleteProfile: boolean;
    displayCosyImages: boolean;
    currency: string;
    iframe: boolean;
    noManualAdd: boolean;
    variant: string;
    vehicleName: string;
    useModelYearOnAddVehicle: boolean;
    marketModelName: string;
    addVehicleName: string;
    anyAdvisor: boolean;
    advisorImgs: boolean;
    seasonalImgs: boolean;
    zoom: number;
    multilingual: boolean;
    regio: string;
    region: string;
    language: string;
    homeUrl: string;
    allModelsUrl: string;
    servicesUrl: string;
    worldUrl: string;
    menuFiveUrl: string;
    buildUrl: string;
    lifestyleUrl: string;
    brochureUrl: string;
    careersUrl: string;
    contactUrl: string;
    newsletterUrl: string;
    corporateSalesUrl: string;
    motorradUrl: string;
    cookieUrl: string;
    disclaimerUrl: string;
    facebookUrl: string;
    instagramUrl: string;
    youtubeUrl: string;
    twitterUrl: string;
    lineUrl: string;
    nonAutoline: NonAutoline;
    preventMultipleBookings: boolean;
    levelOneDealers: LevelOneDealerUrl[];
}

class SpecialDays {
    day: number;
    startTime: string;
    endTime: string;
    exclusionTimes: ExclusionTimes[];
}

class NonAutoline {
    hasRecall: boolean;
    leadTime: number;
    blockDays: number[];
    startTime: string;
    endTime: string;
    intervals: number;
    exclusionTimes: ExclusionTimes[];
    exclusionDates: ExclusionDates[];
    specialDays: SpecialDays[];
    serviceItems: ServiceItems[];
}

class ServiceItems {
    translation: string;
    code: string;
}

class Dealer {
    dealerCode: string;
    brands: string[];
    url: string;
    levelOne?: LevelOne;
}

class LevelOne {
    blockDays: number[];
    startTime: string;
    endTime: string;
    intervals: number;
    exclusionTimes: ExclusionTimes[];
    exclusionDates: ExclusionDates[];
    specialDays: SpecialDays[];
}

class ExclusionDatesOld {
    date: string;
}

export  interface ExclusionDates {
    year: number, 
    month: number, 
    day: number
}

class ExclusionTimes {
    startTime: string;
    endTime: string;
}

export class Language {
    language: string;
    direction: string;
    description: string;
    abbreviation: string;
    descriptionAlt: string;
    abbreviationAlt: string;
    position: number;
    switch: boolean;
}

class FormValidation {
    mobileTelNo: string;
    mobileTelNoMsg: string;
    postalCode: string;
    password: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,24})";
    regNumber: string;
    maxMobileLength?: string;
    minMobileLength?: string;
}

export type LevelOneDealerUrl = {
    dealerName: number;
    dealerUrl: number;
}
