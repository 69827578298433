import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ServicesService } from 'src/app/services/services/services.service';
import { ServiceItemModel } from 'src/app/services/services/service-item.model';
import { UserService } from 'src/app/services/user-account/user/user.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BookingService } from 'src/app/services/booking/booking.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-service-item',
  templateUrl: './select-service-item.component.html',
  styleUrls: ['./select-service-item.component.less']
})
export class SelectServiceItemComponent implements OnInit {
  @Output() servicesSelected: EventEmitter<string> = new EventEmitter();
  @Output() previousState: EventEmitter<string> = new EventEmitter();

  brand: string;
  serviceItems: ServiceItemModel[];
  includeNotes: boolean;
  serviceNotes: string;
  selectServices: boolean;
  technicalCampaignMessageLink: string;
  contactnumber: string;
  isRtl: boolean;
  isFullRecall: boolean;
  charactersLeft = 255;
  showExceededCharactersErrorMessage = false;
  deepLinkServiceItems: string[] | string
  isNewZealand: boolean
  isSingapore: boolean
  showsServiceItemPrices: boolean = false


  constructor(
    private readonly servicesService: ServicesService,
    private readonly userService: UserService,
    private readonly configService: ConfigService,
    private readonly translateService: TranslateService,
    private readonly bookingService: BookingService,
    private readonly router: Router,

  ) { }

  ngOnInit() {
    this.showsServiceItemPrices = this.configService.configSettings.showsServiceItemPrices
    this.isNewZealand = this.configService.configSettings.country === "New Zealand";
    this.isSingapore = this.configService.configSettings.country === "Singapore"
    this.deepLinkServiceItems = JSON.parse(sessionStorage.getItem("deepLinkRecommendedService")) ;
    this.brand = this.configService.brand;
    this.contactnumber = this.userService.selectedDealer.Telephone;
    this.technicalCampaignMessageLink = this.configService.configSettings.technicalCampaignMessageLink;
    this.userService.SelectedVehicle$.subscribe(result => {
      if (result)
        this.getServiceItems();
    });

    this.isRtl = this.translateService.currentLang.includes('ar_');
    this.translateService.onLangChange.subscribe(result => {
      this.isRtl = result.lang.includes('ar_');
    });
  }

  getServiceItems() {
    if (this.userService.selectedDealer && this.userService.selectedDealer.TitanDealer) {
      this.servicesService.getServiceItemsTitan().pipe(take(1)).subscribe(result => {
        this.setUpServices(result);
      });
    } else {
      this.translateService.onLangChange.subscribe(() => {
        this.servicesService.getRecommendedService().pipe(take(1)).subscribe(result => {
          this.setUpServices(result);
        });
      });
      this.servicesService.getRecommendedService().pipe(take(1)).subscribe(result => {
        this.setUpServices(result);
      });
    }

  }

  setUpServices(services: ServiceItemModel[]) {
    this.serviceItems = services.filter(x => !x.productCode.toUpperCase().includes('NOTES'));
    this.includeNotes = services.filter(x => x.productCode.toUpperCase().includes('NOTES')).length > 0;
    if (this.includeNotes)
      this.userService.serviceNotesItem = services.filter(x => x.productCode.toUpperCase().includes('NOTES'))[0];
    if (this.userService.selectedServices) {
      this.userService.selectedServices.forEach(service => {
        this.serviceItems[this.serviceItems.findIndex(x => x.productCode === service.productCode)].selected = true;
      });
    }

    if (this.isNewZealand && this.deepLinkServiceItems && this.deepLinkServiceItems.length > 0) {
      if( this.deepLinkServiceItems instanceof Array) {
        this.deepLinkServiceItems.forEach(service => {
          this.serviceItems[this.serviceItems.findIndex(x => x.productCode === service)].selected = true;
        });
      } else {

          this.serviceItems[this.serviceItems.findIndex(x => x.productCode === this.deepLinkServiceItems)].selected = true;

      }



    }
  }

  updateServices() {
    if (this.isServiceItemSelected()) {
      if (this.serviceNotes)
        this.userService.serviceNotes = this.serviceNotes;
      this.userService.selectedServices = this.serviceItems.filter(x => x.selected);
      if (this.isTechnicalCampaignSelected()) {
        // This was moved to the method sendTechnicalCampaignMailToDealer in booking.service.ts !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        this.servicesSelected.emit('steps-service-type')
        // this.bookingService.updateBooking().pipe(take(1)).subscribe(() => {
        //   this.bookingService.sendTechnicalCampaignMail().pipe(take(1)).subscribe(result => {
        //     if (result)
        //       this.router.navigateByUrl('/booking-confirmation');
        //   });
        // });
      } else {
        this.servicesSelected.emit('steps-service-type');
      }

    } else
      this.selectServices = true;
  }

  isTechnicalCampaignSelected(): boolean {
    return !this.configService.configSettings.fullRecallEnabled &&
      this.serviceItems &&
      this.serviceItems.find(x => x.productCode.includes('SOLRECA') &&
        x.selected) !== undefined;
  }

  isFullRecallSelected(): boolean{
    return this.configService.configSettings.fullRecallEnabled &&
    this.serviceItems &&
    this.serviceItems.find(x => x.productCode.includes('SOLRECA') &&
      x.selected) !== undefined;
  }

  previous() {
    this.previousState.emit('steps-service-type');
  }

  isServiceItemSelected() {
    if (!this.serviceItems) return false;
    return !this.includeNotes ? this.serviceItems.filter(x => x.selected).length > 0 :
      this.serviceItems.filter(x => x.selected).length > 0 || (this.serviceNotes && this.serviceNotes.length > 0);
  }

  selectServiceItem(item: ServiceItemModel) {
    item.selected = !item.selected;

    if (this.configService.configSettings.fullRecallEnabled) {
      this.isFullRecall = item.productCode.includes('SOLRECA');
    } else {
      if (item.productCode.includes('SOLRECA')) {
        this.serviceItems.forEach(x => {
          if (item.productCode !== x.productCode)
            x.selected = false;
        });
      } else {
        const recallItem = this.serviceItems.find(x => x.productCode.includes('SOLRECA'));
        if (recallItem && recallItem.selected) {
          recallItem.selected = false;
        }
      }
    }
  }

  hasAdditionalItems() {
    return this.serviceItems &&
      this.serviceItems.filter(item => item.productCode.includes('S_')).length > 0;
  }

  deductCharacters(event: Event) {
    this.charactersLeft = (255 - (<HTMLInputElement>event.target).value.length);
  }
}
